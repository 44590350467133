<template>
    <div class="container">
        <div class="operationBtns topBtns" v-if="canEdit">
            <el-button type="primary" icon="el-icon-plus" style="margin-right:10px;"
                @click="routerChange(1)">新增资源</el-button>
            <resource-upload-btn ref="resource-upload-btn" @handleUpdate="pageChange(1)"></resource-upload-btn>
        </div>
        <div class="lr-box">
            <div class="border-container">
                <ResDirectory ref="res-dir-1" class="left"  :styleType="'large'" :width="'auto'"
                    @handlePathLabel="handlePathLabel" :canEdit='canEdit ? true : false'>
                </ResDirectory>
                <div class="right">
                    <div class="info-container">
                        <el-breadcrumb separator="/" class="resBreadcrumb">
                            <template v-if="breadcrumbData.length">
                                <el-breadcrumb-item v-for="(item, index) of breadcrumbData" :key="index">
                                    <span class='breadcrumb-item'>{{item}}</span>
                                </el-breadcrumb-item>
                            </template>
                            <template v-else>
                                <el-breadcrumb-item>
                                    <span class='breadcrumb-item'>资源文件夹</span>
                                </el-breadcrumb-item>
                            </template>
                        </el-breadcrumb>
                        <div class="search">
                            <el-row type="flex" justify="flex-end" >
                                <el-col :span="8" class="search-item">
                                    <el-input class="circular-bead-input search-item-input" clearable @keyup.enter.native="pageChange(1)"
                                        @clear="pageChange(1)" placeholder="请输入名称" prefix-icon="el-icon-search" v-model="searchForm.name">
                                    </el-input>
                                </el-col>
                                <el-col :span="8" class="search-item">
                                    <el-input class="circular-bead-input search-item-input" clearable @keyup.enter.native="pageChange(1)"
                                        @clear="pageChange(1)" placeholder="请输入主题" prefix-icon="el-icon-search" v-model="searchForm.topic">
                                    </el-input>
                                </el-col>
                                <el-col :span="8" class="search-item">
                                    <el-select class="circular-bead-select search-item-input" clearable v-model="searchForm.resource_type" placeholder="资源类型"
                                        @change="pageChange(1)">
                                        <el-option v-for="item of resourceTypeList" :label="item.label" :value="item.value" :key="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div class="table-container" >
                        <el-table ref="multipleTable" :data="resourceList" tooltip-effect="dark" style="width: 100%"
                            size="medium" @selection-change="handleSelectionChange"
                            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                            :cell-style="{height: '50px',padding: '5px 0'}" :row-key="getRowKey">
                            <el-table-column type="selection" width="60" :reserve-selection="true" align="center"></el-table-column>
                            <el-table-column label="名称" min-width="240" :show-overflow-tooltip="true">
                                <template slot-scope="scope">
                                    <div class="fz-bold hover-text-colourful" @click="routerChange(3, scope.row)">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="主题" width="140" :show-overflow-tooltip="true" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.topic}}
                                </template>
                            </el-table-column>
                            <el-table-column label="资源类型" width="120" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.type | resourceTypeFilter}}
                                </template>
                            </el-table-column>
                            <el-table-column label="标签" width="200" :show-overflow-tooltip="true" align="center">
                                <template slot-scope="scope">
                                    <div class="resources-tags" v-if="scope.row.labels">
                                        <el-tag effect="plain" type="info" color='transparent'
                                            v-for="(item, index) in scope.row.labels.split(',')" :key="index">
                                            {{ item }}
                                        </el-tag>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="权重" width="120" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.sort}}
                                </template>
                            </el-table-column>
                            <el-table-column label="设计人" width="120" align="center">
                                <template slot-scope="scope">
                                    {{ providerName ? providerName : (scope.row.creator ? scope.row.creator.name : '-')}}
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" width="180" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.create_time | timeFilter}}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" :width="canEdit ? '200' : '100' " align="center" fixed="right">
                                <template slot-scope="scope">
                                    <el-button type="text" class="icon-btn" @click="routerChange(2, scope.row)" v-if="canEdit">
                                        <el-tooltip placement="top" content="编辑">
                                            <i class="iconfont icon-edit" />
                                        </el-tooltip>
                                    </el-button>
                                    <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)" v-if="canEdit">
                                        <el-tooltip placement="top" content="删除">
                                            <i class="iconfont icon-delete" />
                                        </el-tooltip>
                                    </el-button>
                                    <el-button type="text" class="icon-btn" @click="routerChange(3, scope.row)">
                                        <el-tooltip placement="top" content="详情">
                                            <i class="iconfont icon-check" />
                                        </el-tooltip>
                                    </el-button>
                                    <el-button type="text" class="icon-btn" @click="routerChange(4, scope.row)">
                                        <el-tooltip placement="top" content="文件取回">
                                            <i class="iconfont icon-putaway" />
                                        </el-tooltip>
                                    </el-button>
                                </template>
                            </el-table-column>
                            <div class="" slot="empty">
                                <no-data></no-data>
                            </div>
                        </el-table>
                    </div>
                    <div class="page-container with-multiple">
                        <div class="left">
                            <div class="btn-area">
                                <el-button type="primary" icon="el-icon-star-on" round plain :disabled="!multipleSelection.length"
                                    @click="handleMoveResShow" v-if='canEdit'>移至文件夹
                                </el-button>
                                <el-button type="primary" icon="iconfont icon-delete" round plain :disabled="!multipleSelection.length"
                                    @click="handleDeleteSelect" v-if='canEdit'>批量删除
                                </el-button>
                                <!-- <el-button icon="el-icon-star-on" round="" v-if="searchForm.dirId"
                                    :disabled="!multipleSelection.length" @click="handleRemoveResFormDir">
                                    从文件夹移除
                                </el-button> -->
                            </div>
                            <div class="selected-sum">
                                <span>已选中 {{multipleSelection.length || 0}} 条</span>
                            </div>
                        </div>

                        <el-pagination class="right" layout="total, sizes, prev, pager, next" background
                            @size-change="pageSizeChange"
                            @current-change="pageChange"
                            :current-page="currentPage"
                            :page-sizes="[total, 10, 15, 20, 25]"
                            :total="total" :page-size="pageSize">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="请选择文件夹"  class="resList-moveRes-dialog"
            :visible.sync="moveResShow"  @closed="handleMoveResClosed">
            <ResDirectory  :width="'auto'" @handleNodeClick="handleMoveResNodeClick" :styleType="'small'"></ResDirectory>
            <span slot="footer" class="dialog-footer">
                <el-button round @click="handleMoveResClose">取 消</el-button>
                <el-button round type="primary" @click="handleAddResToDir">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';
import axios from 'axios'

export default {
    components: {
        ResDirectory: () => import('@/components/ResDirectory'),
        ResourceUploadBtn: () => import('@/components/resources/ResourceUploadBtn'),
        NoData: () => import('@/components/Empty.vue')
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            resourceList: [],
            multipleSelection: [],
            labelList: Core.Const.RESOURCE.LABEL_MAP_LIST,
            typeList: Core.Const.RESOURCE.TYPE_MAP_LIST,
            templateTypeList: Core.Const.RESOURCE.TEMPLATE_TYPE_MAP_LIST,
            resourceTypeList: Core.Const.RESOURCE.TYPE_MAP_LIST,
            searchForm: {
                name: '',
                template_type: '',
                resource_type: '',
                topic: '',
                dirId: ''
            },
            breadcrumbData: [],
            moveResShow: false,
            targetDirId: '',
            userType: Core.Data.getUserType(),
            providerName: Core.Data.getProvider(),
        };
    },
    created() {
        this.getResourceList();
    },
    methods: {
        getResourceList() {
            Core.Api.Resource.list(
                this.currentPage,
                this.pageSize,
                this.searchForm.name,
                this.searchForm.template_type,
                this.searchForm.resource_type,
                this.searchForm.topic,
                this.searchForm.dirId,
            ).then((res) => {
                this.total = res.count;
                this.resourceList = res.list;
            });
        },
        handleMoveResShow() {
            this.moveResShow = true;
        },
        handleMoveResClose() {
            this.moveResShow = false;
        },
        handleMoveResClosed() {
            this.targetDirId = '';
            this.$refs['res-dir-1'].initDirTree();
        },
        handleMoveResNodeClick(dirId) {
            this.targetDirId = dirId;
        },
        handleAddResToDir() { // 确定 移动资源至文件夹
            const resourceIds = JSON.parse(
                JSON.stringify(this.multipleSelection)
            ).join();
            Core.Api.Resource.moveToDir(resourceIds, this.targetDirId).then(
                (res) => {
                    this.$message.success('添加成功');
                    this.handleMoveResClose();
                    this.$refs.multipleTable.clearSelection();
                }
            );
        },
        handleRemoveResFormDir() {
            const resourceIds = JSON.parse(
                JSON.stringify(this.multipleSelection)
            ).join();
            this.$confirm('确定将资源移出本文件夹?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Resource.moveToDir(resourceIds, 0).then((res) => {
                    this.$message.success('移除成功!');
                    this.pageChange(1);
                    this.$refs.multipleTable.clearSelection();
                });
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val.map((item) => item.id);
            console.log(this.multipleSelection);
        },
        pageChange(page) {
            this.currentPage = page;
            this.getResourceList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getResourceList();
        },
        routerChange(key, q = '') {
            console.log('q', q)
            switch (key) {
                case 1:
                    this.$router.push('/resources/resources-edit');
                    break;
                case 2:
                    console.log('q.media_type', q.media_type);
                    if (q.media_type === 2) {
                        this.$refs['resource-upload-btn'].handleDialogShow(q)
                    } else if (q.media_type === 1 || q.media_type === 0) {
                        this.$router.push({ path: 'resources-edit', query: { id: q.id } });
                    }
                    break;
                case 3:
                    if (q.media_type === 2) {
                        this.goPDF(q)
                    } else if (q.media_type === 1 || q.media_type === 0) {
                        this.$router.push({ path: '/resources/resources-view', query: { id: q.id } });
                    }
                    break;
                case 4:
                    this.recoverOssFile(q.files)
            }
        },
        handleDelete(id) { // 删除单个资源
            this.$confirm('确定要删除这个资源吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Resource.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1);
                });
            });
        },
        handleDeleteSelect() { // 删除多个资源
            this.$confirm('确定要删除多个资源吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Resource.multipleDelete(
                    this.multipleSelection.join(',')
                ).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1);
                    this.$refs.multipleTable.clearSelection();
                });
            });
        },
        handlePathLabel(pathLabelArr, activeDirId) {
            this.breadcrumbData = pathLabelArr;
            this.searchForm.dirId = activeDirId;
            this.pageChange(1);
        },
        getRowKey(row) {
            return row.id;
        },
        goPDF(q) {
            axios.get(Core.Const.NET.FILE_URL_PREFIX + Core.Const.UPLOAD_FILE_SUFFIX.OTHER + '/' + q.files)
            .then(res => {
                if(res.status === 200) {
                    const newWindow = this.$router.resolve({
                        path: '/public-part/pdf-reader', query: {
                        pdf: q.files
                    }})
                    window.open(newWindow.href,'_blank')  
                }
            })
            .catch(err => {
                this.$message.error(err.message || err)
                // if(typeof err === 'object' && (err.message.indexOf('403') !== -1)) {
                //     setTimeout(() => {
                //         this.$message.info('当前文件已过期, 请点击右侧操作: 文件取回')
                //     }, 500);
                // }
            })
        },
        recoverOssFile(file) {
            let f = ''
            let fileArray = []
            try {
                // array    resource/resources-view
                f = JSON.parse(file)
                fileArray = f.map(i => i.path.split('com/')[1])
            } catch (e) {
                // string   goPDF
                f = file
                fileArray.push('file/file/' + f)
            }
            let data = encodeURIComponent('file_keys') + '=' + encodeURIComponent(fileArray.toString())
            axios.post(Core.Const.NET.URL_POINT + '/file/oss-restore-list', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(res => {
                this.$message.success("正在进行文件取回, 找回时间预计3-5分钟")
            }).catch(err => {
                this.$message.error(err.message || err)
            })
        },
        // 单文件 pdf
        recoverOssFile2(file) {
            let data = encodeURIComponent('file_key') + '=' + encodeURIComponent('file/file/' + file)
            axios.post(Core.Const.NET.URL_POINT + '/file/oss-restore', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(res => {
                this.$message.success("正在进行文件取回, 找回时间预计3-5分钟")
            }).catch(err => {
                this.$message.error(err.message || err)
            })
        }
    },
    computed: {
        canEdit() {
            /*
            *  0: 不可新增、编辑、操作文件夹
            *  1: 可新增、编辑、操作文件夹
            */
            let canEdit = 0
            if (this.userType === Core.Const.USER_TYPE.PROVIDER) {
                canEdit = 1
            }
            return canEdit
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .operationBtns{
        display: flex;
        align-items: center;
        min-width: 210px;
    }
    .lr-box {
        padding: 30px;
        .border-container {
            @include flex(row, space-between, flex-start);
            background-color: #fff;
            position: relative;
            &::after {
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 255px;
                background-color: #E6EAEE;
            }
            .left.directory {
                width: 256px;
                border-radius: 6px 0 0 6px;
                border-right: 1px solid transparent;
                overflow: hidden;
            }
            > .right {
                width: calc(100% - 256px);
                background-color: #fff;
                border-radius: 0 6px 6px 0;
                overflow: hidden;
                // padding: 0 18px 32px;
                box-sizing: border-box;
                .info-container {
                    @include flex(row,space-between, center);
                    padding: 10px;
                    border-radius: 0;
                }
                .page-container {
                    border: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    overflow: hidden;
                }
                .table-container {
                    border: 0;
                    padding: 0;
                    border-radius: 6px 6px 0 0;
                    border-bottom: none;
                    .resources-tags {
                        .el-tag {
                            border-radius: 12px;
                            color: #606266;
                        }
                        .el-tag + .el-tag {
                            margin-left: 5px;
                        }
                    }
                }
                .el-breadcrumb{
                    line-height: 22px;
                    .breadcrumb-item{
                        font-weight:400;
                        color:rgba(0,0,0,0.45);
                    }
                }
            }
        }
        .info-container {
            @include flex(row,space-between, center);
            flex-wrap: nowrap;
            .resBreadcrumb {
                width: calc(100% - 580px);
                min-width: 200px;
            }
            .search{
                max-width: 580px;
                .search-item{
                    padding: 0 10px;
                    &-input {
                        max-width: 180px;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.container {
    .lr-box {
        .right{
            .el-breadcrumb{
                .el-breadcrumb__item{
                    &:last-child{
                        .breadcrumb-item{
                            color:rgba(0,0,0,0.65);
                        }
                    }
                    .el-breadcrumb__separator{
                        margin: 0 7px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }
    .resList-moveRes-dialog,
    .upload-btn{
        .el-dialog{
            width: 300px;
            border-radius: 5px;
            overflow: hidden;
            .el-dialog__header,.el-dialog__footer{
                background:rgba(248,249,250,1);
            }
            .el-dialog__header{
                border-bottom:1px solid rgba(234,237,240,1);
            }
            .el-dialog__footer{
                padding: 10px;
                border-top:1px solid rgba(234,237,240,1);
            }
        }
    }
    .resList-moveRes-dialog{
        .el-dialog{
            height: 70vh;
            .el-dialog__body{
                padding: 0 5px;
                height: calc(100% - 108px);
                overflow: auto;
            }
        }
    }
}
</style>
